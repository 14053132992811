import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import { kr, en } from './locales';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources: {
      // 여기에 사용할 언어와 키와 번역될 키 값을 넣으면 된다.
      // en: {
      //   translation: {
      //     "Welcome to React": "Welcome to React and react-i18next",
      //   },
      // },
      en, kr
    },
    lng: "en",
    fallbackLng: "en",

    interpolation: {
      escapeValue: false,
    },
    detection: { order: ['path', 'navigator'] }
  });


export default i18n;
