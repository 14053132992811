import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { resetArticles, initArticles, addArticle, deleteArticle, selectArticle, DEFAULT_ARTICLE_ID } from '../../redux/articlesSlice';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { setEditorEnabled } from '../../redux/currentSlice';

const ArticleItem = (props) => {
  let selectedStr = (props.selected ? 'selected' : '');

  return (
    <li onClick={(e) => {  e.preventDefault(); props.onSelectArticle(props.selectedIndex, props.id); }}>
      <a className={`item ${selectedStr}`} href='#' onClick={(e) => {  e.preventDefault(); }} title={props.id}>
        {props.title}
      </a>
      <div className='icons'>
        <FontAwesomeIcon icon={faTrashCan}
          onClick={(e) => {e.preventDefault(); props.onDeleteArticle(props.id); }} />
      </div>
    </li>
  )
};

const ArticleList = (props) => {
  const dispatch = useDispatch();
  const articles = useSelector((state) => state.articles.articles);

  const onSelectArticle = (selectedIndex, id) => {
		dispatch(selectArticle({ selectedIndex: selectedIndex, id: id }));
	};

  const onDeleteArticle = (id) => {
		dispatch(deleteArticle({ id: id }));

    console.log('###articles.selectedIndex', articles.selectedIndex)
    if(typeof articles.selectedIndex == 'undefined') {
      dispatch(setEditorEnabled(false));
      // dispatch(resetArticles());
      // dispatch(initArticles());
    }
  }

  return (
    <div className="article-list">
      <ul className='entries'>
        {articles && articles.map((item, index) => {
          return (
            <ArticleItem
              key={index}
              selectedIndex={index}
              id={item.id}
              title={item.title}
              content={item.content}
              selected={item.selected}
              data={item}
              onSelectArticle={onSelectArticle}
              onDeleteArticle={onDeleteArticle}
            />
          );
        }
        )}
      </ul>
    </div>
  );
};

export default ArticleList;
