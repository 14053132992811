import React, { useEffect, useState, useCallback, useRef } from "react";

export const useDebounce = (func, timeout = 100) => {
  let timer;
  let deferred = () => {
    clearTimeout(timer);
    timer = setTimeout(func, timeout);
  };
  const ref = useRef(deferred);
  return ref.current;
};

export const useDebouncedEffect = (func, deps = [], timeout = 100) => {
  useEffect(useDebounce(func, timeout), deps);
}

export const html2Text = (html) => {
  if (typeof html == "undefined" || html == null) return "";

  return html.replace(/<br>/, "\n").replace(/<[^>]*>?/gm, "");
};

export const firstLine = (text) => {
  if (typeof text == "undefined" || text == null) return "";

  return text.split(/[\n|<br>]/)[0];
};

export const text2Html = (text) => {
  if (typeof text == "undefined" || text == null) return "";

  var text = text
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;");

  // 2: Line Breaks
  text = text.replace(/\r\n?|\n/g, "<br>\n");

  // 3: Paragraphs
  //text = text.replace(/<br>[\n\s]*<br>/g, "\n</p><p>&nbsp;</p><p>");

  // 4: Wrap in Paragraph Tags
  text = "<p>" + text + "</p>";

  return text;
};

export const buildTitle = (content) => {
  //console.log(html2Text(content));
  let res = firstLine(html2Text(content)).match(/[^\s].{0,40}/);
  if (res != null && res.length > 0) return res[0];
  else return "";
};

export default { buildTitle, html2Text, text2Html };
