import React from 'react';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'

import SearchBox from './SearchBox';
import ArticleList from './ArticleList';
import './Aside.css';
import { useDispatch } from 'react-redux';
import { addArticle, selectArticle } from '../../redux/articlesSlice';
//import { setContent } from '../../redux/currentSlice';
import { NEW_ARTICLE_TITLE } from '../../constants';

const AddButton = ({onClick}) => {
  return (
    <div id="add" className="add" onClick={onClick}>+</div>
  );
}

const Aside = ({editorState}) => {
  const dispatch = useDispatch();

  const onAddClick = () =>  {
    const newid = +new Date();
    dispatch(addArticle({id: newid, title: NEW_ARTICLE_TITLE, content: ''}));
    dispatch(selectArticle({selectedIndex: 0, id: newid}));
  };

  return (
    <>
      <SearchBox />
      <AddButton onClick={onAddClick}/>
      <ArticleList />
    </>
  )
}

export default Aside;
