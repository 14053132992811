import defaultText from "../../assets/traslations/welcome-ko.txt";

export default {
  mode: 'kr',
  add: '장바구니에 담기',
  cancel: '취소하기',
  placeholder: {
    login: '로그인해주세요',
    comment: '댓글을 남겨주세요',
  },
  defaultText: defaultText,
}
