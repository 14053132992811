import { configureStore } from "@reduxjs/toolkit";
import articlesSlice from "./articlesSlice";
import currentSlice from "./currentSlice";
import storeSynchronize from 'redux-localstore';

const store = configureStore({
  reducer: {
    articles: articlesSlice,
    current: currentSlice,
  }
});

export default store;

storeSynchronize(store); // the default config synchronizes the whole store
