import React, { useEffect, useState, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

//import MyRichEditor from "./components/editor/MyRichEditor.js";
import Aside from "./components/aside/Aside";
// import {
//   Editor,
//   EditorState,
//   RichUtils,
//   getDefaultKeyBinding,
//   ContentState,
//   convertFromRaw,
//   convertToRaw,
// } from "draft-js";
//import { setContent } from "./redux/currentSlice.js";
import { useTranslation, initReactI18next } from "react-i18next";

import "./App.css";
//import draftToHtml from "draftjs-to-html";
import {
  addArticle,
  initArticles,
  selectArticleByIndex,
  DEFAULT_ARTICLE_ID,
  updateArticle,
} from "./redux/articlesSlice.js";
import { setCurrent, setContent, setEditorEnabled } from "./redux/currentSlice.js";

import utils, { useDebouncedEffect } from "./util/util.js";
//import draftUtils from "./util/draftUtils.js";
import MyQuillEditor from "./components/quilleditor/MyQuillEditor";

import { NEW_ARTICLE_TITLE } from './constants';

function App() {
  const dispatch = useDispatch();
  const articles = useSelector((state) => state.articles);
  const current = useSelector((state) => state.current);

  const editorRef = useRef(null);
  const changed = useRef(false);

  const [state, setState] = useState({
    //editorState: EditorState.createEmpty(),
    //editorEnabled: false,
    content: '',
    //changed: false,
  });

  useEffect(() => {
    dispatch(initArticles());
  }, []);

  useEffect(() => {
    console.log("articles.loaded");

    const defaultExists =
      _.findIndex(articles.articles, (x) => x.id === DEFAULT_ARTICLE_ID) >= 0;

    console.log("articles.defaultText", articles.defaultText);

    if (!defaultExists && articles.loaded && articles.defaultText) {
      console.log(
        "utils.text2Html(articles.defaultText",
        utils.text2Html(articles.defaultText)
      );

      let title = String(utils.buildTitle(articles.defaultText));

      dispatch(
        addArticle({
          id: DEFAULT_ARTICLE_ID,
          title: title === "" ? NEW_ARTICLE_TITLE : title,
          content: utils.text2Html(articles.defaultText),
          selected: true,
        })
      );
    }
  }, [articles.loaded]);

  useEffect(() => {
    console.log("articles.articles[articles.selectedId", articles.selectedId);

    let content = "";
    if (typeof articles.selectedIndex != "undefined" && articles.selectedIndex >= 0) {
      console.log("--> _selectedIndex >= 0");
      let _article = articles.articles[articles.selectedIndex];
      if (_article) {
        content = _article.content;

        enableEditor(true);
        setEditorContent(content);
      } else {
        enableEditor(false);
      }
    }
  }, [articles.selectedId]);

  // const throttledSaveContent = useCallback(
  //   _.throttle((content) => {
  //     let article = getArticleById(articles.selectedId);
  //     if (article) {
  //       saveCurrentContentTo(articles.selectedId);
  //     } else {
  //       // disable editor
  //       enableEditor(false);
  //     }
  //   }, 1000),
  //   [state.content, dontGo]
  // );

  //useDebouncedEffect(() => {
  useEffect(
    // _.debounce(
       () => {
        console.log('----------- call saveCurrentContentTo', changed.current);

        if (changed.current == false) {
          console.log('not changed');
          return;
        }

        let article = getArticleById(articles.selectedId);
        if (article) {
          saveCurrentContentTo(articles.selectedId);
          changed.current = false;
          console.log('updated');

        } else {
          // disable editor
          enableEditor(false);
        }
      },
      // 500,
      // { leading: false }
    [changed.current, articles.selectedId, dontGo]);

  // debounce: https://lodash.com/docs/4.17.15#debounce
  const onEditorChange = //useCallback(
    //_.throttle(
    (content) => {
      dontGo(content);

      console.log('onEditorChange content 11111111', content);
      // console.log(
      //   "onEditorChange editorState before set state",
      //   editorToHtml(editorState)
      // );

      setState({ ...state, content });

      // console.log('call setcurrent', content);
      // //dispatch(setCurrent({content}));
      // dispatch(setContent({content}));

      changed.current = true;
      console.log('onEditorChange content changed.current', changed.current);

      // console.log(
      //   "onEditorChange editorState after set state",
      //   editorToHtml(state.editorState)
      // );

      // let article = getArticleById(articles.selectedId);
      // if (article) {
      //   saveCurrentContentTo(articles.selectedId);
      // } else {
      //   // disable editor
      //   enableEditor(false);
      // }
      //},
      // 500,
      // { leading: true }
      //),
      //[state.content, dontGo]
      //);
    };

  const setEditorContent = (content, type = "html") => {
    // let contentState =
    //   type == "html"
    //     ? draftUtils.createWithHtmlContent(String(content))
    //     : ContentState.createFromText(String(content));

    // console.log("setEditorContent", contentState);

    // let newState = {
    //   ...state,
    //   editorState: EditorState.createWithContent(contentState),
    // };

    // if(articleId) {
    //   console.log('cachedCurrentArticleId', articleId);
    //   newState['cachedCurrentArticleId'] = articleId;
    // }

    setState({...state, content});
  };

  const saveCurrentContentTo = (articleId) => {
    console.log("saveCurrentContentTo: ", articleId, current.content);
    let content = state.content; //editorToHtml(state.editorState);
    //let content = current.content;
    let title = utils.buildTitle(content);

    dispatch(updateArticle({
      id: articleId,
      title: title == "" ? NEW_ARTICLE_TITLE : title,
      content,
    }));
  };//, [current.content]);

  // const printCurrentContent = useEffect(() => {
  //   //console.log(state.currentContent);
  // }, [state.currentContent]);

  const enableEditor = (enabled) => {
    if (current.editorEnabled === enabled) return;

    console.log('enableEditor', enabled);

    dispatch(setEditorEnabled({ editorEnabled: enabled }))
  };

  // const editorToHtml = (editorState) => {
  //   if (!editorState) return "";
  //   return draftToHtml(convertToRaw(editorState.getCurrentContent()));
  // };

  const getCurrentArticleId = () => {
    const _articles = articles.articles;
    const _selectedIndex = articles.selectedIndex;
    let currentArticleId = null;
    if (_articles[_selectedIndex])
      currentArticleId = _articles[_selectedIndex].id;

    return currentArticleId;
  };

  const getArticleById = (articleId) => {
    let idx = _.findIndex(articles.articles, (x) => x.id == articleId);
    return articles.articles[idx];
  };

  // const handleKeyDown = (e) => {
  //   if (e.nativeEvent.isComposing) return;

  //   let precessed = false;
  //   console.log(e);
  //   if(e.altKey && e.key === 'n') {
  //     // new text
  //     console.log('##new text')
  //     precessed = true;
  //   } else if(e.altKey && e.key === 'f') {
  //     // search
  //     console.log('##search')
  //     precessed = true;
  //   } else if(e.altKey && e.key === "ArrowUp") {
  //     // alt-up
  //     console.log('##alt-up')
  //     precessed = true;
  //   } else if(e.altKey && e.key === "ArrowDown") {
  //     // alt-down
  //     console.log('## alt-down')
  //     precessed = true;
  //   } else if(e.altKey && e.key === "s") {
  //     // tab open/cloase
  //     console.log('##side tab open/cloase')
  //     precessed = true;
  //   }

  //   if(precessed)
  //     e.preventDefault();
  // };

  return (
    <div className="App">
      <div className="aside">
        <Aside />
      </div>
      <div className="editor-wrap">
        {current.editorEnabled ? (
          // <MyRichEditor
          //   loaded={articles.loaded}
          //   editorState={state.editorState}
          //   onChange={onEditorChange}
          // />
          <MyQuillEditor
            onChange={onEditorChange}
            value={state.content}
            ref={editorRef}>
          </MyQuillEditor>
        ) : (
          <div />
        )}
      </div>
    </div>
  );
}

// NOTE: https://stackoverflow.com/questions/61785903/problems-with-debounce-in-useeffect
function dontGo(term) {
  //console.log(`processing ${term}`);
}

export default App;
