import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import defaultText from "../assets/traslations/welcome-en.txt";
import axios from "axios";
import _ from "lodash";
//import { useTranslation, initReactI18next } from "react-i18next";
import i18n from '../i18n';
import { defineState } from 'redux-localstore';
import { act } from "react-dom/test-utils";

export const DEFAULT_ARTICLE_ID = '(default)';

export const initArticles = createAsyncThunk('articles/initArticles', async () => {
  console.log('initArticles');
  //const t = i18n.t;
  //const { t } = useTranslation();
  // console.log('defaulttext path', t('main.defaultText'));
  // console.log('defaulttext path1', i18n.t('main.mode'));
  let defaultTextPath = defaultText;
  const response = await axios.get(defaultTextPath);
  return response.data;
});

const initialState = defineState({
  selectedIndex: -1,
  selectedId: '',
  articles: [],
  defaultText: null,
  loaded: false,
})('articles');

export const articlesSlice = createSlice({
  name: "articles",
  initialState,
  reducers: {
    resetArticles: (state, action) => {
      return initialState;
    },
    addArticle: (state, action) => {
      console.log('addArticle', action.payload);
      const newid = action.payload.id ?? +new Date();
      const newArticle = {
        id: newid,
        title: action.payload.title ?? "article-" + new Date(),
        content: action.payload.content,
        selected: action.payload.selected ?? true,
      };

      const articleExists = _.findIndex(state.articles, (x) => x.id == newid) >= 0;
      console.log('articleExists', articleExists)
      if(!articleExists) {
        return {
          ...state,
          selectedIndex: 0,
          selectedId: newid,
          articles: [newArticle, ...state.articles],
        };
      }

      return state;
    },

    deleteArticle: (state, action) => {
      console.log('deleteArticle', action.payload);

      let nextidx = state.selectArticle > 0 ? state.selectArticle - 1 : 0;
      let nextid = state.articles[nextidx] ? state.articles[nextidx].id : '';
      return {
        ...state,
        selectedIndex: nextidx,
        selectedId: nextid,
        articles: state.articles.filter(
          (item) => item.id !== action.payload.id
        ),
      };
    },

    updateArticle: (state, action) => {
      console.log('updateArticle action.payload', action.payload);
      const newarticles = state.articles.map((item) => {
        return item.id == action.payload.id
          ? { ...item,
              title: action.payload.title,
              content: action.payload.content,
            }
          : item;
      });
      return { ...state, articles: newarticles };
    },

    selectArticle: (state, action) => {
      console.log('selectArticle', action.payload);
      const newarticles = state.articles.map((item) => {
        return { ...item, selected: item.id == action.payload.id, };
      });
      let selectedIndex = _.findIndex(state.articles, (x) => x.id == action.payload.id);
      return {
        ...state,
        selectedIndex: selectedIndex,
        selectedId: action.payload.id,
        articles: newarticles,
      };
    },

    selectArticleByIndex: (state, action) => {
      console.log('selectArticleByIndex', action.payload);
      const newarticles = state.articles.map((item, index) => {
        return index == action.payload.selectedIndex
          ? { ...item, selected: true, }
          : { ...item, selected: false, };
      });
      let selected = state.articles[action.payload.selectedIndex];
      return {
        ...state,
        selectedIndex: action.payload.selectedIndex,
        selectedId: selected ? selected.id : '',
        articles: newarticles,
      };
    }
  },
  extraReducers: {
    [initArticles.fulfilled]: (state, { payload }) => {
      return {...state, defaultText: payload, loaded: true}
    },
  },
});

export const { resetArticles, addArticle, deleteArticle, updateArticle, selectArticle, selectArticleByIndex } =
  articlesSlice.actions;

export default articlesSlice.reducer;
