import { createSlice } from "@reduxjs/toolkit";
import { set } from "lodash";
//import { Editor, EditorState, RichUtils, getDefaultKeyBinding, ContentState } from 'draft-js';

export const currentSlice = createSlice({
  name: "current",
  initialState: {
    editorEnabled: false,
    content: null,
    //changed: true,
  },
  reducers: {
    setCurrent: (state, action) => {
      if(typeof action.payload.content != 'undefined')
        state.content = action.payload.content;

      if(typeof action.payload.editorEnabled != 'undefined')
        state.editorEnabled = action.payload.editorEnabled;

      // if(typeof action.payload.changed != 'undefined')
      //   state.changed = action.payload.changed;

      console.log('setCurrent', state.content);

      return state;
    },
    setContent: (state, action) => {
      const content = action.payload.content;
      void(state.content = content);
    },
    setEditorEnabled: (state, action) => {
      const editorEnabled = action.payload.editorEnabled;
      void(state.editorEnabled = editorEnabled);
    },
    // setChanged: (state, action) => {
    //   const changed = action.payload.changed;
    //   void(state.changed = changed);
    // }
  }
});

export const {
  //initEditorState,
  setCurrent,
  setContent,
  setEditorEnabled,
  setChanged,
} = currentSlice.actions;

export default currentSlice.reducer;
